import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import whitelogo from "../images/logo-white.png";
import whatsapp from "../images/whatsapp.png";
import freedesign from "../images/freedesign.svg";

import facebook from "../images/facebook.png";
import linkedin from "../images/linkedin.png";
import instagram from "../images/instagram.png";
import youtube from "../images/youtube.png";

const phoneNumber = "919000014692"; // Replace with the recipient's phone number in international format (no '+' or leading '00')
const message = "Hello, I'm interested in your services!"; // Pre-filled message

const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
  message
)}`;

function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="freedesign">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <img src={freedesign} />
        </a>
      </div>

      <div className="whatsapp">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <img src={whatsapp} />
        </a>
      </div>

      <div className="footer">
        <div className="wrapper">
          <div className="footer-new-wrap">
            <div className="footer-new-1">
              <img src={whitelogo} alt="whitelogo" />
            </div>

            <div className="footer-new-2">
              <h4>Quick Links</h4>
              <ul className="w-50-50">
                <li>
                  <Link to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/Aboutus" onClick={scrollToTop}>
                    About us
                  </Link>
                </li>
                <li>
                  <Link to="/ModularFurniture" onClick={scrollToTop}>
                    Modular furniture
                  </Link>
                </li>
                <li>
                  <Link to="/Granite" onClick={scrollToTop}>
                    Granite
                  </Link>
                </li>
                <li>
                  <Link to="/Quartz" onClick={scrollToTop}>
                    Quartz
                  </Link>
                </li>
                <li>
                  <Link to="/Contactus" onClick={scrollToTop}>
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ModularFurniture/ProductCategories/1"
                    onClick={scrollToTop}
                  >
                    Kitchens
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ModularFurniture/ProductCategories/2"
                    onClick={scrollToTop}
                  >
                    Wardrobes
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ModularFurniture/ProductCategories/3"
                    onClick={scrollToTop}
                  >
                    Bathroom vanities
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ModularFurniture/ProductCategories/4"
                    onClick={scrollToTop}
                  >
                    Consoles
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ModularFurniture/ProductCategories/5"
                    onClick={scrollToTop}
                  >
                    Storage units
                  </Link>
                </li>
                <li>
                  <Link
                    to="/ModularFurniture/ProductCategories/6"
                    onClick={scrollToTop}
                  >
                    Office Furniture
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer-new-3">
              <h4>Contact Us</h4>
              <div className="footer-a-1 f-phone">+91 9000014692</div>
              <div className="footer-a-1 f-mail">info@rostre.in</div>
              <div className="footer-a-1 f-location">
                N Square, 3rd Floor, <br />
                Opp. N Convention,
                <br /> Hitech City Main Rd, <br />
                Hyderabad, <br />
                Telangana- 500081
              </div>
            </div>
          </div>

          <div className="footer-copy-wrap">
            <div className="footer-copy-1">
              &copy; 2024 Rostre Spaces. All Rights Reserved.
            </div>
            <div className="footer-copy-2">
              <Link
                to="https://www.facebook.com/rostrespaces/?rdid=APj7FVFhNQQRJoaf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </Link>
              <Link
                to="https://www.linkedin.com/company/rostre-cabinets/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="linkedin" />
              </Link>
              <Link
                to="https://www.instagram.com/rostrespaces/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="instagram" />
              </Link>
              <Link
                to="https://www.youtube.com/@RostreSpaces-i7o"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="youtube" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
